@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;500&display=swap");

$primary-font: "Montserrat", sans-serif;
$title-font: "Orbitron", sans-serif;
$primary-color: #6a85b6;
$secondary-color: #bac8e0;
$accent-color: #00c6fb;
$accent-dark: #005bea;
$text-dark: #111;
$text-light: #555;
$bg-light: #f9f9f9;

.mentor {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mentor-title {
    font-size: 2.8rem;
    font-family: $title-font;
    padding: 1.5rem 0;
    color: $bg-light;
    text-transform: uppercase;
  }

  .mentor-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .mentor-details {
      position: relative;
      width: 16rem;
      height: 16rem;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
        border: 4px solid #74b9ff;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(0, 198, 251, 0.3) 0%,
            rgba(0, 91, 234, 0.3) 50%,
            rgba(0, 198, 251, 0.3) 100%
          );
          filter: blur(6px);
          z-index: 1;
        }

        .mentor-image {
          transform: scale(1.05);
        }

        .mentor-content {
          transform: translateY(0);
          opacity: 1;
          z-index: 2;
        }
      }

      .mentor-image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .mentor-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
        color: #fff;
        opacity: 0;
        transform: translateY(100%);
        transition: transform 0.5s ease, opacity 0.5s ease;

        h2 {
          font-size: 1.2rem;
          font-weight: 700;
          text-align: center;
          font-family: $primary-font;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 0.9rem;
          color: $secondary-color;
          margin-bottom: 1rem;
        }

        .mentor-button {
          background-color: #74b9ff;
          border: none;
          color: #fff;
          font-size: 0.9rem;
          margin: 7px;
          padding: 0.5rem 1rem;
          border-radius: 20px;
          cursor: pointer;
          transition: background 0.3s ease;

          &:hover {
            // background-image: linear-gradient(to right, $accent-dark, $accent-color);
            background-color: #3b99f8;
            transition: 0.3s ease;
          }
        }
      }
    }
  }
}

.mentor-info {
  .about {
    padding: 3rem 0;

    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: auto;
      padding: 1rem;

      @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
      }

      img {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        object-fit: cover;
        border: 5px solid $secondary-color;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

        @media (max-width: 768px) {
          margin-bottom: 1.5rem;
        }
      }

      .about-text {
        max-width: 600px;
        color: #fff;

        h2 {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          color: $accent-color;
        }

        h3 {
          font-size: 1.5rem;
          color: $accent-dark;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.8;
          text-align: justify;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .mentor-info .about .main {
    flex-direction: column;
    text-align: center;

    .about-text {
      padding: 0 1rem;

      h2,
      h3 {
        text-align: center;
      }
    }
  }

  .mentor .mentor-card-container {
    gap: 1.5rem;

    .mentor-details {
      width: 12rem;
      height: 12rem;
    }
  }
}

@media (max-width: 768px) {
  .mentor {
    .mentor-title {
      font-size: 2rem;
    }

    .mentor-card-container .mentor-details {
      width: 16rem;
      height: 16rem;
    }
  }

  .mentor-info .about .main {
    .about-text {
      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.2rem;
      }
    }
  }
}
