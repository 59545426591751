.shootingstar{
    z-index: 2;
    height: 100vh;
    width: 100vw;
    position: absolute;
    overflow: hidden;
}

.animateShoot span
{
 position: absolute;
 top: 50%;
 left: 50%;
 width: 4px;
 height: 4px;
 background: #fff;
 border-radius: 50%;
 box-shadow: 0 0 0 4px rgba(255,255 ,255 ,0.1),
 0 0 0 8px rgba(255,255 ,255 ,0.1),
 0 0 20px rgba(255,255,255,1);
 animation: animate 3s linear infinite;
 z-index: 1;
}
.animateShoot span::before
{
content: '';
position: absolute;
top: 50%;
transform: translateY(-50%);
width: 300px;
// max-width: 300px;
object-fit: cover;
height: 1px;
background: linear-gradient(90deg,#fff,transparent);
}
@keyframes animate
{
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 0;
    }
    30%
    {
        opacity: 0.6;
    }
    70%
    {
        opacity: 1;
    }
    90%
    {
        opacity: 0.5;
    }
    100%
    {
        transform: rotate(315deg) translateX(-1000px);
        opacity: 0;
    }
}
.animateShoot span:nth-child(1)
{
    top:0px;
    right: 0px;
    left: initial;
    animation-delay:0s;
    // animation-duration: 1s;
    animation-duration: 2.5s;
}
.animateShoot span:nth-child(2)
{
    top:350px;
    right: 0px;
    left: initial;
    // animation-delay:0.2s;
    animation-duration: 3s;
}
.animateShoot span:nth-child(3)
{
    top:80px;
    right: 0px;
    left: initial;
    // animation-delay:0.4s;
    animation-duration: 4.85s;
}
.animateShoot span:nth-child(4)
{
    top:0px;
    right: 180px;
    left: initial;
    // animation-delay:0.6s;
    animation-duration: 6.25s;
}
.animateShoot span:nth-child(5)
{
    top:0px;
    right: 400px;
    left: initial;
    // animation-delay:0.8s;
    animation-duration: 7.25s;
}
.animateShoot span:nth-child(6)
{
    top:0px;
    right: 600px;
    left: initial;
    // animation-delay:1s;
    animation-duration: 3s;
}
.animateShoot span:nth-child(7)
{
    top:300px;
    right: 0px;
    left: initial;
    // animation-delay:1.2s;
    animation-duration: 3.25s;

    // width: 50%;
}
.animateShoot span:nth-child(8)
{
    top:0px;
    right: 700px;
    left: initial;
    // animation-delay:1.4s;
    animation-duration: 5.45s;
}
.animateShoot span:nth-child(9)
{
    top:0px;
    right: 1000px;
    left: initial;
    // animation-delay:0.75s;
    animation-duration: 4.25s;
}
.animateShoot span:nth-child(10)
{
    top:0px;
    right: 450px;
    left: initial;
    // animation-delay:2.75s;
    animation-duration: 5.25s;
}