.footer {
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 120px;
    padding: 0.5rem;

    .copyright {
        margin: 5px 21rem 0 0;
        width: auto;
        font-size: 17px;
    }

    // .footer_spacer{
    //     width : 40rem;
    // }
    .socials {
        margin: 10px 0 7px 15rem;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        .fa-2xl{
            font-size: 1.45em;
        }
    }
  

    .icon {
        margin: 0 13px;

        &:hover {
            color: #74b9ff;
        }
    }


}

@media screen and (max-width: 1080px) {

    .footer {
        height: auto;
        padding: 0.60rem;
        flex-direction: column;

        .copyright {
            margin: 5px auto;
            width: auto;
            font-size: 0.8rem;
            text-align: center;
        }

        .socials {
            margin: 10px 0;
            .fa-2xl{
                font-size: 1.45em;
            }
        }

    }

}