//events css

.events-container {
  padding: 0 1.5rem;
}

.year-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.event-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;

  .event-Wrappercard {
    width: 100%;
    height: 300px;
    border-radius: 15px;
    padding: 1.5rem;
    background: white;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(black, 0.5);

    &:hover {
      transform: translateY(-20px);
      box-shadow: 7px 7px 7px rgba(208, 213, 213, 0.6), 0 5px 5px rgba(192, 199, 201, 0.6);

      transform: scale(1.05);
      z-index: 1;

      &:before {
        opacity: 1;
      }

      .event-info {
        opacity: 1;
        transform: translateY(0px);
      }

      img {
        filter: blur(0.75px);
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background: rgba(black, 0.7);
      z-index: 2;
      transition: 0.5s;
      opacity: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
    }

    .event-info {
      position: relative;
      z-index: 3;
      color: white;
      opacity: 0;
      transform: translateY(30px);

      transition: 0.5s;

      h1 {
        margin: 0px;
      }

      p {
        letter-spacing: 1px;
        font-size: 15px;
        margin-top: 8px;
      }

    }
  }
}

.events-container {
  width: 100%;
  padding: 0rem 1rem;
}

.event-card-container {
  max-width: 1350px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;

}


@media screen and (max-width:1080px) {
  .event-card-container{
      // max-width: 90%;
      margin: auto;
      grid-template-columns: repeat(1,1fr);
      
  }

  .event-wrapper .event-Wrappercard .event-info {
    h1 {
      font-size: large;
    }
    p{
      font-size: 13px;
    }
  }
}


@media screen and (max-width:768px) {
  .event-card-container{
      // max-width: 60%;
      margin: auto;
      grid-template-columns: 1fr;
  }

  .event-wrapper .event-Wrappercard{
    height: 200px;
  }
  .event-wrapper{
    .event-Wrappercard{
      .event-info{
        h1{
          font-size: large;
        }
        p{
          font-size: 13px;
        }
      }
    }
  }
}