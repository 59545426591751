@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap');

.page_body{
    height : 60vh;
    background-color: var(--bgcolor);
}
.page{
    width : 100%;
    background-color: var(--bgcolor);
    background-image: linear-gradient(rgba(0, 0, 0, 0.25) ,var(--bgcolor)),  url("/public/images/background1.jpeg") ; 
    height : 60vh;
    position : relative;
    background-size: cover;
    
}

.page_heading{
    width : 100%;
    height : 100px;
    display : flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    top: 55%;
}

.page h1{
    font-size : 4rem;
    font-family: 'Orbitron', sans-serif;
    padding: 0.6rem 0 1.5rem;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
}

@media screen and (max-width: 1080px) {
    .page{
        background-position: center;
        
    }
    .page_heading{
        align-items: center;
        position: absolute;
        top: 40%;
    }
    .page h1{
        font-size : 3rem;
    }
}