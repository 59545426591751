.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 10vh;
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: rgba(2, 2, 69, 0);
}

.header-bg {
    background-color: rgb(23, 28, 40, 0.75);
    transition: 0.5s;
    backdrop-filter: blur(2px);
}

.header a {
    height: 10vh;
}

.nav-menu {
    display: flex;
}

section img {
    /* height: 54px; */
    margin-top: 10px;
    margin-left: 5px;
}

.nav-menu li {
    padding: 0 1rem;
    list-style-type: none;
}

.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 1080px) {
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.mobile {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu li a {
        font-size: 2rem;
    }

    .hamburger {
        display: initial;
        margin-bottom: 24px;
    }

    ul {
        background-color: #171c28;
    }

    section img {
        margin-top: 19px;
        margin-left: 5px;
        width: 210px;
    }
}
