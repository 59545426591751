@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root
{
  --bgcolor: #171c28;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color : white;
  font-family: 'Quicksand', sans-serif;
  }

a {
  text-decoration: none;
}

html{
  scroll-behavior: smooth;
}

body {
  background-color: var(--bgcolor);

  overflow-x: hidden;/* for shooting stars */
}

/* scrollbar */
::-webkit-scrollbar-track {
  border: 5px solid #171c28;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #74b9ff;
  border-radius: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}

/* scroll bar ends */

/* hover and active effect on navbar */
li a.active {
  /* border-bottom: 2px solid white; */
  color: #74b9ff;
  font-weight: 700;
}

.header a {
  position: relative;
}

.header li a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 1;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.header a:hover:after {
  width: 100%;
  left: 0;
}

/* hover and active effect on navbar ends*/

@keyframes animateDown {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

@keyframes Fade_In {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .5;
  }

  50% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes AnimateBG {

  0% {
    transform: scale(1, 1);
    /* overflow-x : hidden; */
  }

  50% {
    transform: scale(1.25, 1.25);
    overflow-x: hidden;
  }

  100% {
    transform: scale(1, 1);
  }
}