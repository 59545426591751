.button {
    width: 13rem;
    border: 2px solid white;

    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    border-radius: 2rem;

    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.331);
    }


    .button-text {
        font-weight: 500;
    }


}