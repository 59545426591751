@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap');

.title{
    overflow: hidden;
}

.title-bg {
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), #171c28), url("/public/images/background1.jpeg");
    background-size: cover;
    animation: AnimateBG 1 3s;
    z-index: 0; /*for shooting star*/
}

.bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.title-bg::after {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.34); */

}

.title {
    height: 100%;
    width: 100%;
}

.title .content {
    position: absolute;
    transform: translate(-50%, -80%);
    top: 70%;
    left: 50%;
    text-align: center;
    z-index: 3; /* to make sure shooting stars is behind it*/
}

.content h1 {
    font-size: 5rem;
    padding: 0.6rem 0 1.5rem;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    letter-spacing: 7px;
}

.content {
    background-color: rgba(0, 0, 0, 0);
}

.jumping_arrow {
    color: #fff;
    position: absolute;
    transform: translate(-50%, 40vh);
    top: 50%;
    left: 50%;
    text-align: center;
    height: auto;
    z-index:3;
}

.jumping_arrow i {
    height: 40px;
    overflow-x: hidden;
    animation: animateDown infinite 1.5s;
}



@media screen and (max-width: 768px) {
    .title .content {
        transform: translate(-50%, -165%);
    }

    .content h1 {
        font-size: 3rem;
    }

    .title-bg {
        background-position: center;
    }

}
@keyframes lights {
    0% {
      color: hsl(230, 40%, 80%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }
  
    30% {
      color: hsl(230, 80%, 90%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }
  
    40% {
      color: hsl(230, 100%, 95%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 90%, 0.5),
        -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
        0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
    }
  
    70% {
      color: hsl(230, 80%, 90%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }
  
    100% {
      color: hsl(230, 40%, 80%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }
  }

#txt-animation{
    margin: auto;
  /* font-size: 3.5rem; */
  font-weight: 900;
  animation: lights 5s 750ms linear infinite;
  color: hsl(230, 100%, 95%);
  font-family: 'Orbitron', sans-serif;
}