.side_heading {
    text-align: left;

    h1 {
        font-size: 2rem;
        margin: 0.5rem;
    }

    p {
        margin: 0.5rem;
    }
}
@media screen and (max-width: 1080px) {
    .side_heading{
        text-align : center;

    }
}