.jscop_poster {
    height: 100vh;
    background-color: #171c28;
    background-image: url("/public/images/JSCOP6.0.png");
    background-size: auto 90vh;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1080px) {
    .jscop_poster {
        background-size: 100vw auto;
    }

}